import React, { useRef, useEffect, useState } from "react";
import LogoImage from "../../assets/images/logo.webp";
import FraudAudio from "../../assets/audio/fraud.mp3";
// import Play from "../../assets/images/play-white-1.png";
// import Pause from "../../assets/images/pause.png";
import { Link } from "react-router-dom";



const Header = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);

  const togglePlay = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(FraudAudio);
      audioRef.current.loop = true;

      // Play audio immediately after it's created
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((e) => {
          console.error("Audio play failed", e);
          audioRef.current = null;
        });
    } else {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  return (

    <header>
      {/* <audio ref={audioRef} autoPlay loop>
      <source src={FraudAudio} type="audio/mp3" />
      Your browser does not support the audio element.
    </audio> */}
      <p>
      <Link to="/">Home</Link>
      </p>
      <h1>
        <img src={LogoImage} alt="" className="fraud-logo" />
      </h1>
      <span className="mobile-only hamburger">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
        // onClick={() => setToggle(!toggle)}
        >
          <line y1="0.5" x2="18" y2="0.5" stroke="white" />
          <line y1="3.5" x2="18" y2="3.5" stroke="#EB0B05" />
          <line y1="6.5" x2="18" y2="6.5" stroke="#399CB9" />
          <line y1="9.5" x2="18" y2="9.5" stroke="#FFD94C" />
        </svg>
      </span>
      <div className={`social-links ${toggle ? "active" : ""}`}>
        <a className="tg" href="#">
          TG
        </a>
        <a className="twitter" href="#">
          X
        </a>
        <a className="swap" href="#">
          SWAP
        </a>
      </div>
    </header>
  );
};

export default Header;
