import React, { useRef, useEffect, useState } from "react";
import "../../App.css";
import BannerImage from "../../assets/images/banner.webp";
import LogoImage from "../../assets/images/logo.webp";
import Hall from "../../assets/images/hall.webp";
import Bedroom from "../../assets/images/bedroom.webp";
import Chat from "../../assets/images/chat-4.webp";
import Police from "../../assets/images/police-noise.webp";
// import Money from "../../assets/images/falling-money.webp";
import Play from "../../assets/images/play-white-1.png";
import Pause from "../../assets/images/pause.png";
import FraudAudio from "../../assets/audio/fraud.mp3";
import Footer from "../../components/Footer/index";
import EnterScreen from "../../Enter";

function App() {
  const [toggle, setToggle] = useState<boolean>(false);
  const aboutRef = useRef<HTMLDivElement>(null);
  const tokenomicsRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const whitePaperRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMobile, setMobile] = useState(false);
  console.log("isMobile", isMobile);

  useEffect(() => {
    const handler = () => {
      setMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handler);
    window.addEventListener("load", handler);
  }, []);
  useEffect(() => {
    audioRef.current
      ?.play()
      .catch((e) => console.error("Audio play failed", e));
  }, []);

  const togglePlay = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(FraudAudio);
      audioRef.current.loop = true;

      // Play audio immediately after it's created
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((e) => {
          console.error("Audio play failed", e);
          audioRef.current = null;
        });
    } else {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {}, []);

  const scrollToSection = (
    event: React.MouseEvent<HTMLAnchorElement>,
    ref: React.RefObject<HTMLDivElement>
  ) => {
    event.preventDefault();
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <main>
      <EnterScreen
        onClick={() => {
          togglePlay();
        }}
      />
      <header>
        {/* <audio ref={audioRef} autoPlay loop>
          <source src={FraudAudio} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio> */}
        <p onClick={togglePlay}>
          {/* <img src={Play} alt="" />
           */}
          <img
            className="header-audio"
            src={isPlaying ? Pause : Play}
            alt={isPlaying ? "Mute" : "Play"}
          />
        </p>
        <h1>
          <img src={LogoImage} alt="" className="fraud-logo" />
        </h1>
        <span className="mobile-only hamburger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            // onClick={() => setToggle(!toggle)}
          >
            <line y1="0.5" x2="18" y2="0.5" stroke="white" />
            <line y1="3.5" x2="18" y2="3.5" stroke="#EB0B05" />
            <line y1="6.5" x2="18" y2="6.5" stroke="#399CB9" />
            <line y1="9.5" x2="18" y2="9.5" stroke="#FFD94C" />
          </svg>
        </span>
        <div className={`social-links ${toggle ? "active" : ""}`}>
          <a className="tg" href="#">
            TG
          </a>
          <a className="twitter" href="https://twitter.com/home" target="_blank">
            X
          </a>
          <a className="swap" href="#">
            SWAP
          </a>
        </div>
      </header>
      <section className="banner">
        <img src={BannerImage} alt="Banner Image" />
        <nav>
          <ul>
            <li>
              <a
                href="#About"
                className="about-nav-link"
                onClick={(e) => scrollToSection(e, aboutRef)}
              >
                About
              </a>
              <a
                href="#Tokenomics"
                className="tokenomics-nav-link"
                onClick={(e) => scrollToSection(e, tokenomicsRef)}
              >
                Tokenomics
              </a>
              <a
                href="#Chat"
                className="chat-nav-link"
                onClick={(e) => scrollToSection(e, chatRef)}
              >
                Chat
              </a>
              {/* <a
                href="#Whitepaper"
                className="whitepaper-nav-link"
                onClick={(e) => scrollToSection(e, whitePaperRef)}
              >
                Whitepaper
              </a> */}
            </li>
          </ul>
        </nav>
      </section>
      <section id="About" ref={aboutRef} className="about">
        <img src={Police} alt="" />
        <div className="about-content">
          <h2>About</h2>
          <div className="about-content-para mobile-only">
            {/* <p>
              <span className="red">Lorem ipsum dolor </span>sit amet,
            </p>
            <p>
              consectetur <span className="blue">adipiscing elit, sed</span>
            </p>
            <p>do eiusmod</p>
            <p>tempor incididunt ut labore et</p>
            <p>dolore magna aliqua. Ut enim ad minim</p>
            <p>
              veniam, quis <span className="yellow">nostrud exercitation</span>
            </p>
            <p>ullamco</p> */}
            <p>
            Frauds is at the intersection degen and cutting-edge AI technology. We have partnered with industry leading AI and are here to change the game. This is your chance to be a part of it. Frauds is not just a project. It is an eco-system. Look forward up updates improvements and additions to frauds. At our core we are focused on brining not only entertainment to our holders but to assist with the growth of Artificial Intelligence and Machine Learning. Welcome to stage 1.
            </p>
          </div>
          <div className="about-content-para-desktop desktop-only">
            {/* <p>
              <span className="red">Lorem ipsum dolor </span>sit amet,
              consectetur <span className="blue">adipiscing elit, sed</span> do
              eiusmod
            </p>
            <p>
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim
            </p>
            <p>
              veniam, quis <span className="yellow">nostrud exercitation</span>{" "}
              ullamc
            </p> */}
            <p>
            Frauds is at the intersection degen and cutting-edge AI technology. We have partnered with industry leading AI and are here to change the game. This is your chance to be a part of it. Frauds is not just a project. It is an eco-system. Look forward up updates improvements and additions to frauds. At our core we are focused on brining not only entertainment to our holders but to assist with the growth of Artificial Intelligence and Machine Learning. Welcome to stage 1.
            </p>
          </div>
        </div>
      </section>
      <section className="hall">
        <img src={Hall} alt="" />
      </section>

      <section className="money" ref={tokenomicsRef} id="Tokenomics">
        {/* <img src={Money} alt="" /> */}
        <div className="money-content">
          <div className="money-content-info">
            <h2>tokenomics</h2>
            {/* <div className="destop-only desktop">
              <p>
                <span className="red">Lorem ipsum dolor</span> sit amet,
                consectetur <span className="blue">adipiscing</span>{" "}
              </p>
              <p>
                {" "}
                <span className="blue">Elit, Sed</span> do eiusmod{" "}
              </p>
              <p>tempor incididunt ut labor et dolore magna</p>
              <p> aliqua. Ut enim ad minim</p>
              <p>
                {" "}
                veniam, quis{" "}
                <span className="yellow">nostrud exercitation</span> ullamco
              </p>

              
            </div> */}
          </div>

          <div className="money-content-value">
            <ul>
              <li>
                <p>
                  <span>0</span> <span>%</span>
                </p>
                <span>BUY TAX</span>
              </li>
              <li>
                <p>
                  <span>0</span> <span>%</span>
                </p>
                <span>SELL TAX</span>
              </li>
            </ul>
            <p className="supply">SUPPLY</p>
            <p className="value">1,000,000,000</p>
            {/* <div className="mobile">
              <p>
                <span className="red">Lorem ipsum dolor</span> sit amet,{" "}
              </p>
              <p>
                {" "}
                consectetur <span className="blue">adipiscing elit, sed</span>
              </p>
              <p>do eiusmod </p>
              <p>tempor incididunt ut labor et</p>
              <p>dolore magna aliqua. Ut enim ad</p>
              <p>minim</p>
              <p>
                {" "}
                veniam, quis{" "}
                <span className="yellow">nostrud exercitation</span>
              </p>
              <p>ullamco</p>
            </div> */}
          </div>
        </div>
      </section>

      <section className="kitchen">
        <img src={Bedroom} alt="" />
      </section>

      <section className="roadmap">
        <h2>Roadmap</h2>
        <ul className="roadmap-list">
          {[
            {
              title: "Phase 1",
              content: [
                `Voice Protocol V1 launch`,
                `AI Text in chat`,
                `AI Voice notes in chat`,
                `Voice to Voice VC`,
                `V1 test and data collection`,
              ],
              mobile: [
                `Voice Protocol V1 launch`,
                `AI Text in chat`,
                `AI Voice notes in chat`,
                `Voice to Voice VC`,
              ],
              cn: "red",
            },
            {
              title: "Phase 2",
              content: [
                `AI to host twitter spaces`,
                `Voice Protocol V2`,
                `Marketing push`,
                `T1 and T2 influencers`,
                `Additional clones added`,
              ],
              mobile: [
                `AI to host twitter spaces`,
                `Voice Protocol V2`,
                `Marketing push`,
                `T1 and T2 influencers`,
                `Additional clones added`,
              ],
              cn: "yellow",
            },
            {
              title: "Phase 3",
              content: [
                `V3 Launch`,
                `Simultaneous VC launch`,
                `Exchange listings CEX/DEX`,
              ],
              mobile: [
                `V3 Launch`,
                `Simultaneous VC launch`,
                `Exchange listings CEX/DEX`,
              ],
              cn: "blue",
            },
          ].map(({ title, content, cn, mobile }) => {
            return (
              <li className={cn}>
                <span>{title}</span>
                <div>
                  {[...(isMobile ? mobile : content)].map((x) => {
                    return <p>{x}</p>;
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </section>

      <section ref={chatRef} id="Chat" className="chat">
        <div className="img-box">
          <a href="#">Click to chat</a>
          <img src={Chat} alt="" />
        </div>
      </section>

      <Footer></Footer>
    </main>
  );
}

export default App;
