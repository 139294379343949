const Footer: React.FC = () => {
  return (
    <div>
      <footer>
        <p>2023 F.R.A.U.D.S</p>
        <div className="footer-content">
          <a href="/terms-and-condition" title="Terms & Conditions">
            Terms & Conditions
          </a>
          <a href="privacy-policy" title="Privacy Policy">
            Privacy Policy
          </a>
        </div>
      </footer>
    </div>
  )
}
export default Footer