import { useEffect, useRef } from "react";

const EnterScreen = ({ onClick }: { onClick: any }) => {
  const ref: any = useRef();

  useEffect(() => {
    const html: any = document.querySelector("html");
    if (html) html.style.overflowY = "hidden";
  }, []);

  return (
    <div ref={ref} className="enter-screen">
      <button
        className="glow"
        onClick={() => {
          onClick && onClick();
          if (ref.current) {
            ref.current.classList.add("fade-out");
            const html: any = document.querySelector("html");
            if (html) html.style.overflowY = "auto";
          }
        }}
      >
        <span className="dot after-red">E</span>
        <span className="dot after-light-blue">N</span>
        <span className="dot after-yellow">T</span>
        <span className="dot after-light-blue">E</span>
        <span className="dot">R</span>
      </button>
    </div>
  );
};

export default EnterScreen;
