import Header from "../Header";
import Footer from "../Footer";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="pop">
      <Header></Header>
      <main>
        <h1>$FRAUDS Privacy Policy</h1>

        <p>Welcome to $FRAUDS. At $FRAUDS, we take the protection of your Personal Data very seriously.</p>

        <h2>What is Personal Data?</h2>
        <p>Personal Data is information that makes it possible to identify a natural person. This includes, your name, date of birth, address, telephone number, e-mail address, but also your IP address. Anonymous data as such only exists if no personal reference to the user can be made.</p>

        <h2>Categories of data subjects and types of data processed</h2>
        <p>During the course of using our website and services, we will not collect any personal information. </p>

        <h2>Purpose of the processing</h2>
        <p>The Purpose of processing Personal Data are the provision of the coin and website, its functions and contents, responding to contact requests and communicating with users, security measures, and reach measurement/marketing.</p>

        <h2>Relevant legal basis</h2>
        <p>In accordance with the DPA and the GDPR, the following legal basis, unless specifically described below apply to the processing of your Personal Data:</p>
        <ul>
          <li>Consent: the individual has given clear consent to process Personal Data for a specific purpose.</li>
          <li>Contract: the processing is necessary for a contract or because you have asked us to take specific steps before entering into a contract.</li>
          <li>Legal obligation: the processing is necessary for us to comply with the law (not including contractual obligations).</li>
          <li>Legitimate interests: the processing is necessary for our legitimate interests or the legitimate interests of a third party, unless there is a good reason to protect your Personal Data which overrides those legitimate interests.</li>
        </ul>

        <h2>Security of your Personal Data</h2>
        <p>We take appropriate technical and organisational measures in order to ensure a level of protection appropriate to the risk. These measures include, in particular, ensuring the confidentiality, integrity and availability of data by controlling physical access to the data, as well as access to, input, disclosure, ensuring availability and segregation of the data. We also have procedures in place to ensure the exercise of data subjects' rights, deletion of data and response to data compromise. </p>
        <p>Furthermore, we already take the protection of Personal Data into account during the development and selection of hardware, software, and procedures, in accordance with the principle of data protection through technology design and through data protection-friendly default settings.</p>
        <p>However, databases or data sets that include Personal Data may be breached inadvertently or through wrongful intrusion. Upon becoming aware of a data breach, we will notify all affected individuals whose Personal Data may have been compromised, and the notice will be accompanied by a description of action being taken to reconcile any damage as a result of the data breach. Notices will be provided as expeditiously as possible after which the breach was discovered.</p>

        <h2>Your rights</h2>
        <p>You as the Data Subject are be entitled to rely on the following rights. </p>
        <ul>
          <li>Right of access</li>
          <li>Right to rectification</li>
          <li>Right to restriction of processing</li>
          <li>Right to erasure</li>
          <li>Right to information</li>
          <li>Right to data portability</li>
          <li>Right to object</li>
          <li>Right of withdrawal</li>
          <li>Right to complain to a supervisory authority</li>
        </ul>
        <p>The above rights may be limited in some circumstances, for example, if fulfilling your request would reveal Personal Data about another person.</p>

        <h2>Data processing on our website</h2>
        <p>In the following sections, we explain the individual data processed, the purposes of processing, the legal bases, recipients and, where applicable, transfers to third countries.</p>
        <ol type="a">
          <li>Use of cookies
            We think it's important that you have full control over your privacy online. That's why we refrained from placing cookies that are not strictly necessary as such no requirement to obtain consent exists. Please keep in mind that this approach may change and that our approach to cookies may be revised or that it becomes necessary to use a certain cookie to ensure the full functionality and security of our website.</li>
          <li>$FRAUDS Purchases
            To make a purchase, you may need to connect a valid wallet account MetaMask. We would like to point out that we are not responsible for any processing of personal data when connecting your crypto wallet. </li>
        </ol>

        <h2>Controls For Do-Not-Track Features</h2>
        <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. </p>
        <p>As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</p>

        <h2>Do Not Sell</h2>
        <p>We do not sell data to third parties.</p>

        <h2>Children’s Privacy</h2>
        <p>Our services are restricted to users who are 18 years of age or older. We do not knowingly collect Personal Data from anyone under the age of 18. If you suspect that a user is under the age of 18, please contact us.</p>

        <h2>Changes</h2>
        <p>Because we’re always looking for new and innovative ways to improve our website and services, this policy may change over time. We will notify you before any material changes take effect so that you have time to review the changes.</p>

        <h2>Who should I contact for more information?</h2>
        <p>If you have any questions or comments about our Privacy Policy or wish to exercise your rights under applicable laws, please contact us. This Privacy Policy was last updated on Tuesday, December 5, 2023</p>
      </main>
      <Footer></Footer>
    </div>
  )

}
export default PrivacyPolicy;