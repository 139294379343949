import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "../src/pages/Home/index";
import TermsOfService from '../src/components/TermsOfService/index';
import PrivacyPolicy from './components/PrivacyPolicy';


function App() {
  
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms-and-condition" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />


      </Routes>

    </Router>
  );
}

export default App;
